import React from "react"
import Layout from "~components/layout"
import SEO from "~components/seo"
import { useStaticQuery, graphql} from "gatsby"
import { RichText, Date } from "prismic-reactjs"
import Moment from "moment"
import BodyClassName from "react-body-classname"

import styled from "styled-components"
import theme from "~theme/theme"

import { MenuPageWrapper, MenuPageContent, MenuColumns, MenuImage, Person, PersonImage } from "../theme/common"

import MenuPageSidebar from '~components/MenuPageSidebar'

const EventsPage = ({ data }) => {
  if(!data) return null

  const events = data.prismic.allMenus.edges[0].node.events
  if(!events) return null

  return (
    <Layout>
      <SEO title="Events" />
      <BodyClassName className="info-page">
        <MenuPageWrapper>
          <MenuPageSidebar />
          <MenuPageContent>
            {events.map((event, index) =>
              <MenuColumns key={index}>
                <div>
                  <p>
                    {event.name[0].text}<br />
                    {Moment(Date(event.date)).format("D.M.Y")}
                  </p>
                  {RichText.render(event.description)}
                </div>
                <div>
                  <MenuImage image={event.image.url} />
                </div>
              </MenuColumns>
            )}
          </MenuPageContent>
        </MenuPageWrapper>
      </BodyClassName>
    </Layout>
  )
}

// export const query = graphql`
//   {
//     prismic {
//       allMenus {
//         edges {
//           node {
//             events {
//               date
//               description
//               image
//               name
//             }
//           }
//         }
//       }
//     }
//   }
// `

export default EventsPage
